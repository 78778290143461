/* CARD */

.card-background {
    background: #ffdb9c !important;
    border-radius: 5px 5px 0 0;
    -webkit-border-radius: 5px 5px 0 0;
    -moz-border-radius: 5px 5px 0 0;
    -ms-border-radius: 5px 5px 0 0;
    -o-border-radius: 5px 5px 0 0;
    height: 60px !important;
}

.card-background .MuiCardHeader-content .MuiCardHeader-title {
    color: #b6780e !important;
    font-family: system-ui;
    font-variant: all-petite-caps;
    font-size: 28px;
}

/* CONTAINER PADDING */

.res-container-with-flex {
    padding: 10px 15px 10px 15px !important;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: center;
}

.res-container {
    padding: 10px 15px 10px 15px !important;
}

.res-container-2 {
    padding: 30px 15px 30px 31px !important;
}

/* BUTTON */

button#addMore {
    background: #b6780e;
    color: rgb(255, 255, 255);
    width: 150px;
    height: 40px;
    display: flex;
}

button#exportBtn {
    background: #b6780e;
    width: 115px;
    height: 44px;
    color: rgb(255, 255, 255);
}

button#addMore-2 {
    background: #b6780e;
    width: 170px;
    height: 40px;
    color: rgb(255, 255, 255);
}

button#addMoreInverse {
    background: rgb(255, 255, 255);
    padding: 10px;
    color: #b6780e;
    border: 2px solid #b6780e;
    width: 150px;
    height: 40px;
}

button#delete_button {
    background: red;
    color: rgb(255, 255, 255);
    width: 150px;
    height: 40px;
    display: flex;
}

button#delete_inverse {
    background: rgb(255, 255, 255);
    padding: 10px;
    color: red;
    border: 2px solid red;
    width: 150px;
    height: 40px;
}

button#disabledBtn {
    background: #ebebe4;
    width: 150px;
    height: 40px;
    color: darkgray;
    display: flex;
}

button#disabledBtnInverse {
    background: #ebebe4;
    padding: 10px;
    color: darkgray;
    width: 150px;
    height: 40px;
}

/* LINK IN TABLE ROW */

a.addRow {
    color: #b6780e !important;
    font-weight: 800;
    font-size: 19px;
    font-variant: all-small-caps;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    text-decoration: none;
    margin-bottom: 10px;
}

a.addRow:hover {
    text-decoration: underline;
}

/* MATERIAL DATEPICKER */

.css-qqs95p-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
    color: #fff;
    background-color: #f9b94d !important;
}

/* MATERIAL SELECT */

div#outlined-select-gender {
    text-align: left;
}

.css-1ojzxaw-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
    height: auto;
    min-height: 0.4375em !important;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

/* MATERIAL TABLE HEADER */

th.MuiTableCell-root.MuiTableCell-head.MuiTableCell-stickyHeader {
    letter-spacing: 0em;
    font-weight: 500;
    line-height: 1.5rem;
    color: #b6780e;
    font-family: 'Roboto', sans-serif;
    font-size: 0.875rem;
    display: table-cell;
    vertical-align: inherit;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    text-align: left;
    padding: 16px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: #ffdb9c;
}

/* MATERIAL LABEL */

label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiInputLabel-outlined {
    color: #b6780e !important;
    font-size: 16px !important;
    font-weight: 900 !important;
    letter-spacing: 1px;
}

/* MATERIAL INPUT FIELD */

.css-uwx8qp-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #b6780e !important;
}

.MuiOutlinedInput-input {
    padding: 10.5px 14px !important;
}

/* PAPER */

.MuiPaper-root {
    border-radius: 5px !important;
}

/* CUSTOM DATATABLE */

input#searchTxtbox {
    font-size: 14px !important;
    font-weight: 600 !important;
}

/* SIDEBAR CHIPS */

.MuiListItemButton-root {
    color: #b6780e;
}

.MuiListItemButton-root > .MuiListItemIcon-root {
    color: #b6780e;
}

svg.icon.icon-tabler {
    width: 26px !important;
    height: 26px !important;
}

.MuiListItemButton-root.Mui-selected {
    color: #e89e24 !important;
    background-color: #ffffff !important;
}

.MuiListItemButton-root.Mui-selected > .MuiListItemIcon-root {
    color: #e89e24 !important;
}

.MuiListItemButton-root:hover {
    color: #e89e24 !important;
    background-color: #ffffff !important;
}

.MuiListItemButton-root:hover .MuiListItemIcon-root {
    color: #e89e24 !important;
}

/* LOGIN PAGE */

div#logoSection {
    padding-top: 16px;
    margin-bottom: -15px;
}

div#loginGrids {
    margin-top: 25px;
    margin-bottom: 25px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.css-1nfbtuw {
    padding: 50px !important;
}

/* COPYRIGHT SECTION */

.copyrightSection {
    font-size: 17px;
    color: #ffffff;
    font-weight: 700;
    margin-bottom: 15px;
}

.copyrightSection a {
    font-size: 17px;
    color: #ffffff;
    font-weight: 700;
    text-decoration: none;
}

/* USER PROFILE SECTION */

.profileImageSection {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}

/* PROFILE AVATAR */

.profileAvatar {
    color: #f9b94d !important;
    background-color: #f9b94d1a !important;
}

.MuiAvatar-root.MuiAvatar-rounded.MuiAvatar-colorDefault {
    background-color: white;
    color: #e89e24;
}

/* PROFILE SECTION CHIPS */

div#listID:hover {
    background-color: #b6780e1a !important;
    color: #b6780e !important;
    border-radius: 0px !important;
}

div#listID:hover .MuiListItemIcon-root {
    color: #b6780e !important;
}

div#listID:hover p.MuiTypography-root {
    color: #b6780e !important;
}

/* DASHBOARD */

button.MuiButton-contained.chartButton {
    background: #fff !important;
    color: #b6780e !important;
}

div#standard-select-chart {
    margin-right: 20px;
    /* color: #b6780e; */
}

input#dateOfBirth {
    padding: 10.5px 0px 10.5px 14px !important;
}

#myVideo {
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
}

.content {
    position: fixed;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
    color: #f1f1f1;
    width: 100%;
    padding: 20px;
}

.MuiOutlinedInput-input::placeholder {
    color: #b6780e;
    opacity: 0.5;
}

input#dateOfBirth::placeholder {
    color: #b6780e;
    opacity: 0.5;
    text-transform: uppercase;
}

.MuiOutlinedInput-root div button.MuiIconButton-root {
    color: #b6780e !important;
}

li.MuiMenuItem-root.MuiMenuItem-gutters.Mui-selected {
    background-color: rgb(232 157 36 / 15%);
    color: black;
}

.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #b6780e !important;
}

.MuiOutlinedInput-notchedOutline {
    border-color: #b6780e !important;
}

fieldset.MuiOutlinedInput-notchedOutline {
    border-radius: 5px;
}

.MuiOutlinedInput-input {
    padding: 10.5px 14px !important;
    color: #b6780e !important;
    background-color: white !important;
    border-color: #b6780e !important;
}

.MuiOutlinedInput-root {
    background-color: white;
}

.MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular {
    padding: 10px 16px !important;
}

.MuiOutlinedInput-root svg.MuiSvgIcon-root {
    color: #b6780e;
}

div#listID span {
    border-radius: 0px !important;
}

@media (min-width: 900px) {
    .css-14xzl20 {
        margin-left: -256px;
        width: calc(100% - 260px);
    }
}

button.MuiButtonBase-root.MuiPickersDay-root.Mui-selected {
    background: #b6780e !important;
}

.pagination {
    margin: 15px auto;
    display: flex;
    list-style: none;
    outline: none;
    justify-content: center;
}

.pagination > li > a {
    border: 1px solid #dddddd;
    padding: 5px 10px;
    outline: none;
    cursor: pointer;
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
    background-color: #b6780e;
    border-color: #fcfcfc;
    color: white;
    outline: none;
}

.pagination > li > a,
.pagination > li > span {
    color: #b6780e;
}

.pagination > li > a:hover,
.pagination > li > span:hover {
    color: #ffffff;
    background-color: #b6780e;
}

.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
    border-radius: unset;
}

/* ACCESS DENIED */

div#access-denied {
    display: flex;
    height: 700px !important;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}

h1.access-denied-heading {
    font-size: 80px;
    color: #b6780e;
    text-transform: uppercase;
}

h4.access-denied-sub-heading {
    margin-top: 10px;
    color: #b6780e;
    font-size: 28px;
    text-transform: uppercase;
}

svg.MuiSvgIcon-root.LockIcon {
    color: #b6780e;
    font-size: 90px;
    margin-right: 10px;
}

/* REPORTS */

.padding-con {
    padding-right: 20px !important;
    padding-left: 20px !important;
    padding-top: 20px !important;
    margin-bottom: 20px !important;
}

.padding-text {
    padding-right: 20px !important;
    padding-left: 20px !important;
}

.padding-btns {
    padding-right: 20px !important;
    padding-left: 20px !important;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.css-1vunk85-MuiList-root:after {
    display: none !important;
}

button#disabledExportBtn {
    background: #ebebe4;
    width: 115px;
    height: 44px;
    color: darkgray;
}

/* BIRTHDAY CALENDER */

button.PrivatePickersYear-yearButton.Mui-selected,
button.PrivatePickersYear-yearButton.Mui-selected:focus,
button.PrivatePickersYear-yearButton.Mui-selected:hover {
    color: #fff;
    background-color: #b6780e !important;
}

button.PrivatePickersMonth-root {
    background: transparent !important;
    border: 0px !important;
    color: #212121 !important;
    font-size: 0.875rem;
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
    line-height: 1.75;
    outline: 0 !important;
    margin: 4px 0;
    border-radius: 16px;
    cursor: pointer;
    height: 60px;
    width: 100px;
}

button.PrivatePickersMonth-root.Mui-selected,
button.PrivatePickersMonth-root.Mui-selected:focus,
button.PrivatePickersMonth-root.Mui-selected:hover {
    color: #fff !important;
    background-color: #b6780e !important;
}

.MuiMonthPicker-root {
    margin-right: 4px !important;
    margin-left: 4px !important;
}

.layout_sub_heading_txt {
    font-size: 19px;
    font-family: system-ui;
    font-feature-settings: 'pcap', 'c2pc';
    color: #b6780e !important;
    padding-right: 10px;
    margin-block-start: 0em !important;
    margin-block-end: 0em !important;
    text-align: left;
    text-transform: uppercase;
    font-weight: 700;
}

.MuiPaper-root.MuiCard-root.MuiPaper-elevation1.MuiPaper-rounded.res-container {
    width: inherit !important;
    padding: 10px 0px 10px 15px !important;
}

.blue-background {
    background: #f9b94d !important;
    border-radius: 5px 5px 0 0;
    -webkit-border-radius: 5px 5px 0 0;
    -moz-border-radius: 5px 5px 0 0;
    -ms-border-radius: 5px 5px 0 0;
    -o-border-radius: 5px 5px 0 0;
    height: 50px !important;
}

.blue-background > .MuiCardHeader-content > span {
    color: white !important;
    font-family: system-ui !important;
    text-transform: uppercase !important;
    font-size: 20px !important;
}

.padding-con {
    padding-right: 20px !important;
    padding-left: 20px !important;
    padding-top: 20px !important;
    margin-bottom: 10px;
}

.searchbyCustomer span.field_label_txt {
    font-size: 14px;
}

.searchbyCustomer .MuiFormControlLabel-root {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    margin-left: 0px;
    margin-right: 2px !important;
    vertical-align: middle;
    -webkit-tap-highlight-color: transparent;
    margin-bottom: 0px;
}

span.MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label {
    font-family: system-ui !important;
    color: #b6780e !important;
    font-size: 16px;
    font-weight: 500;
}

.apexcharts-zoomin-icon,
.apexcharts-zoomout-icon,
.apexcharts-zoom-icon,
.apexcharts-reset-icon,
.apexcharts-pan-icon {
    display: none !important;
}

.MuiTableContainer-root {
    padding: 0px !important;
}

th.MuiTableCell-root {
    padding: 16px 10px 16px 16px !important;
}

td.MuiTableCell-root {
    padding: 16px 0px 16px 16px !important;
}

input#roleTxt {
    text-transform: capitalize;
}

.modal-background {
    background-color: #ffdb9c !important;
    padding: 10px 20px;
    text-transform: uppercase;
    font-family: system-ui;
    font-weight: 700;
    font-size: 17px;
    color: #b6780e;
}

.modal-title {
    font-family: system-ui;
    font-weight: 700;
    font-size: 17px;
    color: #b6780e;
    letter-spacing: 0.015em;
}
